import React from "react";
import {graphql, Link} from "gatsby";
import Layout from "../components/Layout";
import Metas from "../components/Metas";
import Pagination from "../components/Pagination";
import ArticleListItem from "../components/ListItems/ArticleListItem";
import Breadcrumb from "../components/Breadcrumb";
import {cssCleanClass} from "../Utils/functions";

const ArticleList = ({pageContext, data, location}) => {
    const {
        currentPage,
        numPages,
    } = pageContext

    const pageTitle = "Actualités"

    return (
        <Layout>
            <Metas title={pageTitle}/>
            <section className="section page-content">
                <div className="container mx-auto px-4">
                    <Breadcrumb/>

                    <h1 className="title h1 text-primary" data-typesense-field="title">{pageTitle}</h1>

                    {pageContext.tags && (
                        <div className="articles-themes flex flex-wrap my-8 -mx-2 items-center">
                            <div className="hidden">Filtrer par catégorie</div>
                            <div className="tags flex-initial px-2">
                                <div className="tags-wrapper flex flex-wrap items-center justify-center -mx-2">
                                    <span className="px-2 mb-2">
                                        <Link
                                            to={`/actualites`}
                                            className={`tag-alternative theme theme-all active`}
                                        >
                                            Tous
                                        </Link>
                                    </span>
                                    {pageContext.tags.map((tag, index) => (
                                        <span key={index} className="px-2 mb-2">
                                            <Link
                                                to={`/actualites${tag.path}`}
                                                className={`tag-alternative theme theme-${cssCleanClass(tag.color)}`}
                                            >
                                                {tag.name}
                                            </Link>
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="columns grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                        {data.articles.nodes.map((node, index) => (
                            <div key={index} className={`column article`}>
                                <ArticleListItem content={node}/>
                            </div>
                        ))}
                    </div>

                    <Pagination
                        path={location.pathname}
                        currentPage={currentPage}
                        numPages={numPages}
                    />
                </div>
            </section>
        </Layout>
    )
}

export default ArticleList

export const query = graphql`query actualiteListQuery($skip: Int!, $limit: Int!) {
  articles: allNodeArticle(
    limit: $limit
    skip: $skip
    filter: {status: {eq: true}}
    sort: {fields: [field_display_date, created], order: DESC}
  ) {
    nodes {
      id
      title
      created
      day: field_display_date(formatString: "DD", locale: "fr")
      month: field_display_date(formatString: "MMM", locale: "fr")
      year: field_display_date(formatString: "YY", locale: "fr")
      path {
        alias
      }
      body {
        value
        summary
      }
      relationships {
        image: field_image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 352
                height: 222
                quality: 100
                transformOptions: {cropFocus: CENTER}
                layout: CONSTRAINED
              )
            }
          }
        }
        tags: field_tags {
          name
          color: field_color
          path {
            alias
          }
        }
      }
    }
  }
}
`
