import React from "react";
import PropTypes from "prop-types";
import {Link, graphql, useStaticQuery} from "gatsby";
import {isBrowser} from "../Utils/functions";

/**
 * @param separator
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Breadcrumb = ({separator, ...props}) => {
    const aliases = useStaticQuery(graphql`
    query {
      pages: allNodePage {
        edges {
          node {
            title
            path {
              alias
            }
          }
        }
      }
      articles: allNodeArticle {
        edges {
          node {
            title
            path {
              alias
            }
          }
        }
      }
      events: allNodeEvenement {
        edges {
          node {
            title
            path {
              alias
            }
          }
        }
      }
      tags: allTaxonomyTermTags {
        edges {
          node {
            title: name
            path {
              alias
            }
          }
        }
      }
      portals: allMenuLinkContentMenuLinkContent(
        filter: {
          enabled: {eq: true}, 
          menu_name: {eq: "main"}, 
          drupal_parent_menu_item: {eq: null}
        },
      ) {
        edges {
          node {
            title
            link {
              uri
            }
          }
        }
      }
    }
  `)

    const basePath = "/"

    const matchWithDrupalAliases = (pathname) => {
        let match = null

        // For each node of each types
        for (let type in aliases) {
            aliases[type].edges.forEach(({node}, index) => {
                switch (type) {
                    case 'portals':
                        let [type, alias] =  node.link.uri.split(':')

                        if (type === 'internal' && alias === pathname) {
                            match = node.title

                            return
                        }
                        break;
                    default:
                        if (node.path.alias === pathname) {
                            match = node.title

                            return
                        }
                        break;
                }
            })
        }

        return match
    }

    const improveCrumbsLabel = (pathname, label) => {
        let title = matchWithDrupalAliases(pathname)

        if (null !== title) {
            return title
        } else {
            let crumbLabel = label.replace(/-/g, ' ')
            return crumbLabel.charAt(0).toUpperCase() + crumbLabel.slice(1)
        }
    }

    let crumbs = []

    if (isBrowser()) {
        let fullPathname = window.location.pathname
        let pathName = ''

        fullPathname.split('/').forEach((segment, index) => {
            if (segment === '') {
                crumbs[index] = {
                    pathName: basePath,
                    crumbLabel: "Accueil"
                }
            } else {
                pathName += '/' + segment

                crumbs[index] = {
                    pathName: pathName,
                    crumbLabel: improveCrumbsLabel(pathName, segment)
                }
            }
        })
    }

    return (
        <nav className="breadcrumb" aria-label="Breadcrumb" {...props}>
            <ol className="breadcrumb__list">
                {crumbs.map(({pathName, crumbLabel}, index) => {
                    let isFirst = (index === 0)
                    let isLast = (index + 1 === crumbs.length)

                    return (
                      <li key={index} className="breadcrumb__list__item">
                          {!isFirst && (
                              <span className="breadcrumb__separator" aria-hidden="true">{separator}</span>
                          )}
                          {isLast ? (
                              <span className="breadcrumb__link__disabled">{crumbLabel}</span>
                          ) : (
                              <Link to={pathName} className="breadcrumb__link">{crumbLabel}</Link>
                          )}
                      </li>
                    )
                })}
            </ol>
        </nav>
    )
}

Breadcrumb.propTypes = {
    separator: PropTypes.string,
}

Breadcrumb.defaultProps = {
    separator: " › ",
}

export default Breadcrumb