import React from "react";
import {Link} from "gatsby";
import PropTypes from "prop-types";

const Pagination = ({path, currentPage, numPages, hasArrows, hasTotal, displayPagesLinks, pageListLimit}) => {
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = (currentPage - 1) === 1 ? '' : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()

    let pageList = [];

    path = path.substr(0, path.split('/', 2).join('/').length)

    if (displayPagesLinks) {
        for (let i = 0; i < numPages; i++) {
            let page = i + 1

            pageList.push(
                <Link key={i} className={`pagination-link pagination-page-link${currentPage === page ? ` active` : ``}`} to={`${path}${page > 1 ? `/${page}` : ``}`}>{page}</Link>
            )
        }
    }

    return (
        <>
            {numPages > 1 && (
                <nav className="pagination flex justify-center items-center mt-8" role="navigation"
                     aria-label="pagination">
                    {hasArrows && (
                        <Link
                            className={`pagination-link pagination-previous${isFirst ? ` invisible` : ``}`}
                            to={`${path}/${prevPage}`}
                            rel="prev"
                        >
                            <i className="fas fa-arrow-left"><span className="hidden">Page précédente</span></i>
                        </Link>
                    )}
                    {pageList}
                    {hasTotal && (
                        <div className="pagination-link pagination-list">
                            {currentPage} / {numPages}
                        </div>
                    )}
                    {hasArrows && (
                        <Link
                            className={`pagination-link pagination-next${isLast ? ` invisible` : ``}`}
                            to={`${path}/${nextPage}`}
                            rel="next"
                        >
                            <i className="fas fa-arrow-right"><span className="hidden">Page suivante</span></i>
                        </Link>
                    )}
                </nav>
            )}
        </>
    )
}

Pagination.propTypes = {
    path: PropTypes.string.isRequired,
    currentPage: PropTypes.number.isRequired,
    numPages: PropTypes.number.isRequired,
    hasArrows: PropTypes.bool,
    hasTotal: PropTypes.bool,
    displayPagesLinks: PropTypes.bool,
    pageListLimit: PropTypes.number
}

Pagination.defaultProps = {
    hasArrows: false,
    hasTotal: false,
    displayPagesLinks: true,
    pageListLimit: 5
}

export default Pagination
